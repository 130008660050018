import React, { useEffect, useState } from 'react'
import Seo from '../components/Seo'
import Footer from '../components/Footer'
import Menu from '../components/Menu'
import { useLangContext } from '../context/lang.context'
import FixedBackground from '../components/FixedBackground'
// import PrivacyPopup from '../components/PrivacyPopup'
import FormPopup from '../components/FormPopup'

import { seo, intro, main, features, sections } from '../content/north-america'
import IntroRegion from '../components/IntroRegion'
import { graphql, useStaticQuery } from 'gatsby'
import FeaturesRegion from '../components/FeaturesRegion'
import RegionContry from '../components/RegionContry'
import BannerCompany from '../components/BannerCompany'
import AllRegions from '../components/AllRegions'
import IndexContact from '../sections/IndexContact'
import Home from '../components/Home'

const NorthAmerica = () => {
  const { lang } = useLangContext()

  const { image } = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/regions/na/intro_na.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: JPG
          )
        }
      }
    }
  `)

  return (
    <>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        url={seo.url}
        image={seo.image}
        isContact
      />
      <FixedBackground />
      <Home />
      <IntroRegion image={image} imgPosition={'0% 0%'} intro={intro} />
      <FeaturesRegion
        features={features}
        label={main.label[lang]}
        title={main.title[lang]}
        desc={main.desc[lang]}
      />
      {/* {sections?.length > 0 &&
        sections.map((section, id) => <RegionContry data={section} key={id} />)} */}
      <AllRegions id={seo.id} />
      <BannerCompany />
      <IndexContact />
      <Footer />
      <FormPopup />
      {/* <PrivacyPopup /> */}
      <Menu />
    </>
  )
}

export default NorthAmerica
